<template>
    <component :is="SkinComponent" :board="board" :me="me" v-model="comment" @save="save" @like="like" @unlike="unlike"></component>
</template>

<script>
import api from "@/api";

export default {
    props: {
        skin: { type: String, default: null },
        board: { type: Object, default: null },
        me: { type: Object, default: null },
    },
    data() {
        return {
            comment: {
                _board: this.$props.board._id,
                content: null,
            },
        };
    },
    methods: {
        async save() {
            try {
                var { comment } = await api.v1.boards.comments.post(this.comment);
                alert("저장되었습니다");
                this.$set(this.board, "comments", [comment, ...this.board.comments]);
                this.$set(this.board, "_comments", [comment._id, ...this.board._comments]);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async like(comment) {
            try {
                var { like } = await api.v1.me.likes.post({
                    _comment: comment._id,
                });
                alert("좋아요 되었습니다");
                this.$set(this.me, "likes", [like, ...this.me.likes]);
                this.$set(comment, "likeCount", +comment.likeCount + 1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async unlike(comment) {
            try {
                await api.v1.me.likes.delete({
                    _id: this.me.likes.find((like) => like._comment == comment._id)._id,
                });
                alert("좋아요가 취소되었습니다");
                this.$set(
                    this.me,
                    "likes",
                    this.me.likes.filter((like) => like._comment != comment._id)
                );
                this.$set(comment, "likeCount", +comment.likeCount - 1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
    computed: {
        SkinComponent() {
            return () => import(`./skin/${this.$props.skin}/comment-list.vue`);
        },
        accessToken() {
            return this.$store.state.accessToken;
        },
        payload() {
            return this.$store.state.payload;
        },
    },
};
</script>
